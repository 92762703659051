<template>
  <div class="activityPoster">
    <div class="bgPoster">
      <img
        src="../../assets/activity/bg_all_marketing.jpg"
      >
      <div
        class="ruleBtn c-ff6456 flexBox h-center"
        @click="showRule"
      >
        活动规则
      </div>
      <div
        v-if="activeInfo.status == 2"
        class="formPost c-fff"
      >
        <div class="title">
          【员工内购专享通道】
        </div>
        <Field
          v-model="resourcesName"
          name="resourcesName"
          placeholder="您的姓名"
        />
        <Field
          v-model="resourcesMobile"
          style="margin-top:14px;"
          name="resourcesMobile"
          placeholder="您的电话"
          type="number"
          maxlength="11"
        />
        <div class="flexBox derectionColumn mt30">
          <img
            class="btnImg"
            src="../../assets/activity/submitBtn_all_marketing.png"
            alt=""
            @click="submitForm"
          >
          <img
            class="btnImg mt15"
            src="../../assets/activity/sharefrend.png"
            alt=""
            @click="sharePost"
          >
        </div>
      </div>
      <div
        v-else
        class="formPost disable flexBox h-center derectionColumn"
      >
        <img
          class="noData"
          src="../../assets/activity/disableActive.png"
          alt=""
        >
        <div>活动已结束，期待下次参与</div>
      </div>
    </div>
    <CreatedPost
      v-if="showShareBox"
      :list="posterList"
      :params="params"
      @closeShare="closeShare"
    />
    <Popup
      v-model:show="showRuleBox"
      round 
      closeable
      position="bottom"
      :style="{ height: '250px' }"
    >
      <div class="c-333 p35 tipsss">
        <div class="ruleTitle">
          活动规则
        </div>
        <div>· 活动期间，凡是哈德教育内部员工亲友报名学历提升/考研辅导课程，即可申领取最高7000元亲友助学补贴，享受低价入学福利；</div>
        <div>· 亲友同行并成功报名，最高可获得800元转介奖金；</div>
        <div>· 亲友成功报名学历提升/ 考研辅导课程，即可赠送一门指定职业资格证课程（七选一）；</div>
        <div>· 名额有限，先到先得。</div>
      </div>
    </Popup>
  </div>
</template>
<script setup>
    import { reactive, ref, onMounted, } from 'vue';
    import { Toast,Field,Popup } from 'vant';
    import { activityVisit,submitVisit,getPosterList} from "@/api/activity"
    import {wxGetRedict,wxGetOpenid} from  '@/api/pay'
    import {wxUtil} from '@/utils/wx_util.ts'
    import {uuid,isPhoneNumber} from "@/utils/tools"
    import {useRoute } from 'vue-router'
    import {useStore} from 'vuex'
    import CreatedPost from "./components/createdPost.vue";
    const hdUuid = ref('')
    const route = new useRoute()
    const store = useStore()
    const resourcesMobile = ref(''),resourcesName = ref('')
    const showShareBox = ref(false),posterList = ref([]),showRuleBox=ref(false),activeInfo=ref({
        
    })
    const params = ref({
            wxOpenid:'',
            hdUuid:hdUuid.value,
            joinerId:route.query.joinerId
     })
    const closeShare = ()=>{
        showShareBox.value = false  
    }
    
    onMounted( async () => {
        if(wxUtil.checkIsInWx()){
            if(!store.state.user.openId){
              let currentUrl = JSON.stringify({
                name:route.name,
                query:route.query
              })
              let authUrl = await wxGetRedict({redirectUri:location.origin + '/getAuthUrl?p='+currentUrl})
              location.href = authUrl
            }else{
                params.value.wxOpenid = store.state.user.openId
            }
        
        }else{
            params.value.wxOpenid = ''
        }
        if(localStorage.getItem('uuid')){
            hdUuid.value = localStorage.getItem('uuid')
        }else{
            hdUuid.value = uuid()
            localStorage.setItem('uuid', hdUuid.value )
        }
        params.value.hdUuid = hdUuid.value
        activeInfo.value = await activityVisit({
            ...params.value,
            posterId:route.query.posterId,
            referrer:location.href
        });
        console.log(activeInfo.value)
    })
    const submitForm = async ()=>{
        if(!resourcesName.value || !resourcesMobile.value){
            Toast("请填写完整的姓名和电话");
            return 
        }
        if(!isPhoneNumber(resourcesMobile.value)){
            Toast("请填写正确的手机格式");
            return 
        }
        await submitVisit({
            resourcesName:resourcesName.value,
            resourcesMobile:resourcesMobile.value,
            posterId:route.query.posterId,
            sharedId:route.query.sharedId || '',
            referrer:location.href,
            ...params.value,
        });
        Toast("提交成功");
    }   

    const sharePost = async ()=>{
        let data = await getPosterList({
            ...params.value,
        });
        console.log(data)
        posterList.value = data
        setTimeout(()=>{
            showShareBox.value = true  
        },500)
    }

    const showRule = ()=>{
        showRuleBox.value = true
    }
</script>
<style lang="scss" scoped>
    .mt30{
        margin-top:30px;
    }
    .mt15{
        margin-top:25px;
    }
    .p35{
        padding:35px;
    }
    .tipsss{
        line-height: 44px;
        .ruleTitle{
            font-size: 30px;
            text-align: center;
            font-weight: bolder;
            margin-bottom:25px; 
        }
        div{
            font-size: 28px;
            margin-bottom:15px;
        }
    }
    .activityPoster{
        
        :deep(.van-cell) {
            padding:9px 18px !important;
            border-radius: 5px;
        }
        .bgPoster{
            position: relative;
            width:100%;
            img{
                width:100%;
            }
            .ruleBtn{
                position:absolute;
                top:25px;
                right:40px;
                width: 160px;
                height:44px;
                border-radius: 90px;
                border: 1px solid #FF6456;
                text-align: center;
                font-size:24px;
            }
            .c-ff6456{
              color:#FF6456;
            }
            .formPost{
                position: absolute;
                top:22.4%;
                height:410px;
                left:40px;
                right: 40px;
                border: 1px solid #FFFFFF;
                border-radius: 22px;
                backdrop-filter: blur(0.8px);
                padding:30px 38px;
                
                .title{
                    font-size: 39px;
                    text-align: center;
                    margin-bottom:30px;
                    font-weight: 800;
                }
                .btnImg{
                    width:370px;
                 
                }
            }
            .disable{
                background:#fff;
                color:#222222;
                font-size: 28px;
                font-weight: bolder;
                .noData{
                    width:285px;
                }
            }
        }
      
    }
</style>
